@import '../../../assets/styles/resources/variables/base';
@import '../../../assets/styles/resources/mixins';
@import '../../../assets/styles/resources/other';
@import '../../../assets/styles/resources/pac';
@import '../../../assets/styles/resources/transitions';
@import '../../../assets/styles/globals/layout';
@import '../../../assets/styles/globals/normalize';
@import '../../../assets/styles/patterns/product-colors';
@import '../../../assets/styles/patterns/icons';
@import '../../../assets/styles/patterns/layout';
@import '../../../assets/styles/patterns/other';
@import '../../../assets/styles/patterns/toggle';

.container {
  width: calc(100% - 4px);
  margin-left: 4px;
  & > h3 {
    margin-bottom: 30px;
  }
}

.item {
  position: relative;
  padding-left: 30px;
  max-width: calc(100% - 6px);
  left: 6px;

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    top: 0;
    width: 16px;
    height: 16px;
    background-color: var(--colors-primary);
    border-radius: 50%;
    z-index: 1;
  }
}

.content {
  position: relative;
  top: -4px;
}
