:global {
  .chevron-down {
    background: url('../../../assets/images/icons/select-chevron-down.png')
      no-repeat;
    background-size: contain;
    height: 6px;
    width: 10px;
  }

  .search-glass {
    background: url('../../../assets/images/icons/search-glass.png') no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
  }

  .arrow-link {
    background: url('../../../assets/images/icons/long-arrow-right-dark.png')
      no-repeat center;
    background-size: 13px;
    cursor: pointer;
    display: block;
    height: 25px;
    position: relative;
    width: 25px;
  }

  .pdf {
    background: url('../../../assets/images/icons/icon-pdf@2x.png') no-repeat
      center;
    background-size: contain;
    height: 50px;
    width: 50px;
  }

  .external-link--primary {
    background: url('../../../assets/images/icons/icon-link@2x.png') no-repeat
      center;
    background-size: contain;
    cursor: pointer;
    display: block;
    height: 16px;
    position: relative;
    width: 16px;
  }

  .more-ellipses {
    background: url('../../../assets/images/icons/ellipses@2x.png') no-repeat
      center;
    background-size: contain;
    cursor: pointer;
    height: 6px;
    outline: none;
    width: 25px;
    position: relative;
  }

  .info {
    background: url('../../../assets/images/icons/info@2x.png') no-repeat center;
    background-size: contain;
    height: 20px;
    width: 20px;
  }

  .check-circle-primary {
    background: url('../../../assets/images/icons/check-circle-primary@2x.png')
      no-repeat center;
    background-size: contain;
    height: 20px;
    width: 20px;
  }

  .check-circle-blue {
    background: url('../../../assets/images/icons/check-blue@2x.png') no-repeat
      center;
    background-size: contain;
    height: 20px;
    width: 20px;
  }

  .reorder-arrows {
    background: url('../../../assets/images/icons/reorder-arrows@2x.png')
      no-repeat center;
    background-size: contain;
    height: 18px;
    margin: auto;
    width: 14px;
  }

  .reorder-arrows-white {
    background: url('../../../assets/images/icons/reorder-arrows-white@2x.png')
      no-repeat center;
    background-size: contain;
    height: 18px;
    margin: auto;
    width: 14px;
  }

  .plus-primary {
    background-image: url('../../../assets/images/icons/plus@2x.png');
    background-size: cover;
    height: 48px;
    width: 48px;
  }

  .primary-dot {
    background: url('../../../assets/images/icons/primary-dot@2x.png') no-repeat
      center;
    background-size: contain;
    height: 18px;
    width: 18px;
  }

  .x-icon {
    background: url('../../../assets/images/icons/x-icon@2x.png') no-repeat
      center;
    background-size: contain;
    height: 12px;
    width: 12px;
  }

  .x-icon-white {
    background: url('../../../assets/images/icons/x-icon-white@2x.png')
      no-repeat center;
    background-size: contain;
    height: 32px;
    width: 32px;
  }

  .sort-asc {
    background: url('../../../assets/images/icons/sort-asc@2x.png') no-repeat
      center;
    background-size: contain;
    height: 12px;
    width: 10px;
  }

  .sort-desc {
    background: url('../../../assets/images/icons/sort-desc@2x.png') no-repeat
      center;
    background-size: contain;
    height: 12px;
    width: 10px;
  }

  .sort-neutral {
    background: url('../../../assets/images/icons/sort-neutral@2x.png')
      no-repeat center;
    background-size: contain;
    height: 12px;
    width: 10px;
  }

  .calendar {
    background: url('../../../assets/images/icons/calendar@2x.png') no-repeat
      center;
    background-size: contain;
    height: 20px;
    width: 20px;
  }

  .download-teal {
    background: url('../../../assets/images/icons/download-teal@2x.png')
      no-repeat center;
    background-size: contain;
    height: 16px;
    width: 16px;
  }

  .upload-teal {
    background: url('../../../assets/images/icons/download-teal@2x.png')
      no-repeat center;
    background-size: contain;
    transform: rotate(180deg);
    height: 16px;
    width: 16px;
  }

  .circle-grey-chevron-left {
    background: url('../../../assets/images/icons/circle-grey-chevron-left@2x.png')
      no-repeat center;
    background-size: contain;
    height: 24px;
    outline: none;
    width: 24px;
  }

  .circle-grey-chevron-right {
    background: url('../../../assets/images/icons/circle-grey-chevron-right@2x.png')
      no-repeat center;
    background-size: contain;
    height: 24px;
    outline: none;
    width: 24px;
  }

  .trash-icon {
    background: url('../../../assets/images/icons/trash@2x.png') no-repeat
      center;
    background-size: contain;
    height: 16px;
    outline: none;
    width: 16px;
  }

  .trash-red-icon {
    background: url('../../../assets/images/icons/trash-red@2x.png') no-repeat
      center;
    background-size: contain;
    height: 16px;
    outline: none;
    width: 16px;
  }

  .camera-icon {
    background: url('../../../assets/images/icons/camera@2x.png') no-repeat
      center;
    background-size: contain;
    height: 24px;
    outline: none;
    width: 24px;
  }

  .star {
    background: url('../../../assets/images/icons/star@2x.png') no-repeat center;
    background-size: cover;
    height: 16px;
    width: 16px;
  }

  .star-off {
    background: url('../../../assets/images/icons/star-off@2x.png') no-repeat
      center;
    background-size: cover;
    height: 16px;
    width: 16px;
  }

  .star-half {
    background: url('../../../assets/images/icons/star-half@2x.png') no-repeat
      center;
    background-size: cover;
    height: 16px;
    width: 16px;
  }

  .remove {
    background: url('../../../assets/images/icons/icon-remove@2x.png') no-repeat
      center;
    height: 16px;
    width: 16px;
    background-size: 12px;
  }

  .email {
    background: url('../../../assets/images/icons/email@2x.png') no-repeat
      center;
    height: 20px;
    width: 20px;
    background-size: contain;
  }
}
