/* These styles are for overriding the Places Auto Complete (pac) API
   These need to be injected for the styling to be consistent with Porter
*/
:global {
  .pac-container {
    background-color: #f1f4f2;
    z-index: 12;
  }
  .pac-container .pac-item,
  .pac-container .pac-item-query,
  .pac-container span {
    font-size: 12px;
    line-height: 20px;
    color: #1c1c1c;
    font-weight: normal;
  }

  .pac-container .pac-item {
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
  }

  .pac-container .pac-item:hover {
    color: #1b7b76;
    background-color: #f1f4f2;
  }

  .pac-container .pac-item:hover .pac-item-query,
  .pac-container .pac-item:hover span {
    color: #1b7b76;
  }

  .pac-icon {
    display: none;
  }

  .pac-logo:after {
    padding: 0;
    height: 0;
    background-image: none;
  }

  .hdpi.pac-logo:after {
    background-image: none;
  }
}
