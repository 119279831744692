@import '../../../assets/styles/resources/variables/base';
@import '../../../assets/styles/resources/mixins';
@import '../../../assets/styles/resources/other';
@import '../../../assets/styles/resources/pac';
@import '../../../assets/styles/resources/transitions';
@import '../../../assets/styles/globals/layout';
@import '../../../assets/styles/globals/normalize';
@import '../../../assets/styles/patterns/product-colors';
@import '../../../assets/styles/patterns/icons';
@import '../../../assets/styles/patterns/layout';
@import '../../../assets/styles/patterns/other';
@import '../../../assets/styles/patterns/toggle';

// input

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// select
.select__disabled {
  color: var(--colors-grey);
}

.select__error {
  border: 1px solid var(--colors-red);
}

// checkbox styles

input[type='checkbox'] {
  position: absolute;
  visibility: hidden;
}

.focused {
  border: 1px solid var(--colors-black);
}

.error {
  border: 1px solid var(--colors-red);
}
