@import '../../../assets/styles/resources/variables/base';
@import '../../../assets/styles/resources/mixins';
@import '../../../assets/styles/resources/other';
@import '../../../assets/styles/resources/pac';
@import '../../../assets/styles/resources/transitions';
@import '../../../assets/styles/globals/layout';
@import '../../../assets/styles/globals/normalize';
@import '../../../assets/styles/patterns/product-colors';
@import '../../../assets/styles/patterns/icons';
@import '../../../assets/styles/patterns/layout';
@import '../../../assets/styles/patterns/other';
@import '../../../assets/styles/patterns/toggle';

.subsection-title {
  width: 100%;
  margin-bottom: 10px;
}
