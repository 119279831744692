:global {
  %base-transition {
    -webkit-transition: 0.15s ease-in-out;
    -moz-transition: 0.15s ease-in-out;
    -ms-transition: 0.15s ease-in-out;
    -o-transition: 0.15s ease-in-out;
    transition: 0.15s ease-in-out;
  }

  %base-transition-slow {
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  %base-transition-turtle {
    -webkit-transition: 0.8s ease-in-out;
    -moz-transition: 0.8s ease-in-out;
    -ms-transition: 0.8s ease-in-out;
    -o-transition: 0.8s ease-in-out;
    transition: 0.8s ease-in-out;
  }

  %base-transition-slowest {
    -webkit-transition: 2s ease-in-out;
    -moz-transition: 2s ease-in-out;
    -ms-transition: 2s ease-in-out;
    -o-transition: 2s ease-in-out;
    transition: 2s ease-in-out;
  }
}
