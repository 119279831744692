:global {
  .row-flex-start {
    display: flex;
    justify-content: flex-start;
  }

  .row-flex-end {
    display: flex;
    justify-content: flex-end;
  }

  .row-flex-center {
    display: flex;
    justify-content: center;
  }

  .row-flex-space-between {
    display: flex;
    justify-content: space-between;
  }

  .row-flex-space-around {
    display: flex;
    justify-content: space-around;
  }

  .full-width {
    width: 100%;
  }

  .half-width {
    width: 50%;
  }

  .one-third-width {
    width: 33.33%;
  }

  .two-third-width {
    width: 66.66%;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .align-items-flex-start {
    align-items: flex-start;
  }

  .align-items-flex-end {
    align-items: flex-end;
  }

  .align-items-center {
    align-items: center;
  }

  .spacer-bottom-10 {
    margin-bottom: $spacer-10;
  }

  .spacer-bottom-20 {
    margin-bottom: $spacer-20;
  }

  .spacer-bottom-30 {
    margin-bottom: $spacer-30;
  }

  .spacer-bottom-40 {
    margin-bottom: $spacer-40;
  }

  .spacer-bottom-60 {
    margin-bottom: $spacer-60;
  }

  .spacer-top-120 {
    margin-top: $spacer-120;
  }

  .spacer-top-60 {
    margin-top: $spacer-60;
  }

  .spacer-top-30 {
    margin-top: $spacer-30;
  }

  .spacer-bottom-90 {
    margin-bottom: $spacer-90;
  }

  .spacer-bottom-120 {
    margin-bottom: $spacer-120;
  }

  .spacer-bottom-240 {
    margin-bottom: $spacer-240;
  }

  .spacer-right-10 {
    margin-right: $spacer-10;
  }

  .spacer-right-20 {
    margin-right: $spacer-20;
  }

  .spacer-right-30 {
    margin-right: $spacer-30;
  }

  .spacer-right-40 {
    margin-right: $spacer-40;
  }

  .spacer-right-50 {
    margin-right: $spacer-50;
  }

  .spacer-right-60 {
    margin-right: $spacer-60;
  }

  .spacer-left-10 {
    margin-left: $spacer-10;
  }

  .spacer-left-20 {
    margin-left: $spacer-20;
  }

  .spacer-left-30 {
    margin-left: $spacer-30;
  }

  .input-container {
    text-align: left;
    width: 100%;

    input {
      width: 100%;
      max-height: 29px;
      margin-top: 10px;
    }
  }
}
