%base-shadow {
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.15);
}

.no-border {
  border-top: none !important;
}

%menu-shadow {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}

$dropdownZIndex: 3;
$overlayZIndex: 5;
