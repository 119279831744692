:global {
  .snow {
    background: var(--colors-cream);
  }

  .sand {
    background: var(--colors-sand);
  }

  .fog {
    background: var(--colors-fog);
  }

  .carbon {
    background: var(--colors-black);
  }

  .coral {
    background: var(--colors-coral);
  }

  .canyon {
    background: var(--colors-canyon);
  }

  .meadow {
    background: var(--colors-meadow);
  }

  .forest {
    background: var(--colors-forest);
  }

  .sky {
    background: var(--colors-sky);
  }

  .midnight {
    background: var(--colors-midnight);
  }

  .stone {
    background: var(--colors-stone);
  }

  .sedona {
    background: var(--colors-sedona);
  }

  .sunrise {
    background: var(--colors-sunrise);
  }
}
