// ******************************************
// Copied over from Porter
//
// todo - RENAME THIS TO TYPOGRAPHY - this looks like an actual typography file
// need to consolidate this and typography files
//  to remove duplication, unneeded styles, and
//  potentially reudce file size
// ******************************************
:global {
  html,
  body {
    height: 100%;
    margin: 0;
    overflow-x: auto;
    padding: 0;
    width: 100%;
  }

  html {
    /* defines 1rem = 10px value */
    font-size: 62.5%;
    overflow: hidden;
  }

  body {
    overflow: auto;
    background: var(--colors-background-color);
    color: var(--colors-black);
    font-weight: normal;
    line-height: 20px;
    margin: 0;
    padding: 0;
    min-height: -webkit-fill-available;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    min-width: 320px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.5rem;

    li {
      padding: 0;
    }
  }

  * {
    resize: none;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1.5rem;
    line-height: 2.2rem;
    text-align: left;

    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;

    -moz-osx-font-smoothing: grayscale;
  }

  a {
    cursor: pointer;
    text-decoration: none;

    &:focus {
      outline: 0;
    }

    &:hover,
    &:active,
    &:visited {
      text-decoration: none;
    }
  }

  img {
    pointer-events: none;
    max-width: 100%;
  }

  select {
    appearance: none;
    outline: none;
    background: var(--colors-pickers-background);
    border-radius: 0;
    color: var(--colors-inputs-foreground);
    line-height: 20px;
    min-width: 120px;
    width: 100%;
    flex: 1;
    background-image: url('../../images/icons/select-chevron-down.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    padding: 5px 30px 5px 10px;
    font-size: 18px;
    cursor: pointer;
    border: none;

    &::placeholder {
      color: var(--colors-grey);
    }

    &::-ms-expand {
      display: none;
    }
  }

  input {
    outline: 0px;
    color: var(--colors-inputs-foreground) !important;
    background: var(--colors-pickers-background);
    padding: 0 4px;
    line-height: 20px;
    @extend %base-transition;

    &[type='password'] {
      letter-spacing: 2.4px;
    }

    &::placeholder {
      letter-spacing: normal;
    }
  }
}

div[class*='StyledModalBody'] {
  div[class*='StyledDateRange'] {
    background-color: var(--colors-modal-pickers-background);
  }
}

div[class*='StyledModalBody'] div[class*='StyledParentContainer'],
div[class*='StyledModalBody'] div[class*='StyledInputWrapper'] {
  div[class*='StyledInputContainer'],
  div[class*='StyledContainer'] {
    background-color: var(--colors-modal-pickers-background);
  }
}
